import axios from 'utils/axios'

const useClientCase = () => {
  const clientCaseGet = async (clientCaseID, includeJoins) => {
    try {
      const { data } = await axios.get(`/case/get`, {
        params: {
          clientCaseID: clientCaseID,
          includeJoins: Boolean(includeJoins),
        },
      })
      return data
    } catch (error) {
      // do something with error
      return null
    }
  }

  const clientCaseSearch = async (searchTerms) => {
    try {
      const { data } = await axios.post(`/case/search`, searchTerms)
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const clientCaseConstantsGet = async () => {
    try {
      const { data } = await axios.get(`/case/constants`)
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const clientCaseUpdate = async (payload) => {
    try {
      const { data } = await axios.post('/case/update', payload)
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  // triage case, throw error if occurs.
  const clientCaseTriage = async (payload) => {
    const { data } = await axios.post('/case/triage', payload)
    return data
  }

  const clientCaseActionLogSearch = async (searchTerms) => {
    try {
      const { data } = await axios.get(`/case/action/search`, {
        params: searchTerms,
      })
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const clientCaseActionHistory = async (clientCaseID) => {
    try {
      const { data } = await axios.get(`/case/history`, {
        params: { clientCaseID: clientCaseID },
      })
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const clientCaseNoteSearch = async (searchTerms) => {
    try {
      const { data } = await axios.get(`/case/note/search`, {
        params: searchTerms,
      })
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const clientCaseNoteGet = async (clientCaseNoteID) => {
    try {
      const { data } = await axios.get(`/case/note/get`, {
        params: { clientCaseNoteID: clientCaseNoteID },
      })
      return data
    } catch (error) {
      // do something with error
      return null
    }
  }

  const clientCaseNoteCreate = async (
    clientCaseID,
    clientCaseNoteVisibility,
    clientCaseNoteContent
  ) => {
    try {
      const { data } = await axios.post(`/case/note/create`, {
        client_case_id: clientCaseID,
        client_case_note_visibility: clientCaseNoteVisibility || null,
        client_case_note_content: clientCaseNoteContent || '',
      })
      return data
    } catch (error) {
      // do something with error
      return null
    }
  }

  const clientCaseDocumentList = async (searchTerms) => {
    try {
      const { data } = await axios.get(`/case/document/list`, {
        params: searchTerms,
      })
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const clientCaseDocumentsUpload = async (clientCaseID, files, visibility) => {
    if (!Array.isArray(files) || files.length === 0) {
      throw new Error('A file must be uploaded')
    }
    try {
      const formData = new FormData()
      formData.append('clientCaseID', clientCaseID)
      for (let i in files) {
        formData.append(`FileUpload_${i}`, files[i])
      }
      if (visibility) {
        formData.append('visibility', visibility)
      }

      // build fromdata
      const { data } = await axios.put(`/case/document/upload`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const clientCaseCustomerOneTimeUpload = async (
    oneTimeToken,
    clientCaseID,
    files
  ) => {
    if (!Array.isArray(files) || files.length === 0) {
      return true
    }
    try {
      const formData = new FormData()
      formData.append('clientCaseID', clientCaseID)
      for (let i in files) {
        formData.append(`FileUpload_${i}`, files[i])
      }
      const res = await axios.put(
        `/customer/onetime/document/upload`,
        formData,
        {
          headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${oneTimeToken}`,
          },
        }
      )
      console.log(res)
      return true
    } catch (error) {
      return false
    }
  }

  const clientCaseDocumentDownload = async (clientCaseDocumentID) => {
    try {
      // build fromdata
      const { data } = await axios
        .get(`/case/document/download`, {
          params: { clientCaseDocumentID: clientCaseDocumentID },
          responseType: 'blob',
        })
        .then((response) => {
          let filename = ''
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers['content-type'],
            })
          )
          let disposition = response.headers['content-disposition']

          if (disposition && disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
              matches = filenameRegex.exec(disposition)

            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }

          let a = document.createElement('a')
          if (typeof a.download === 'undefined') {
            window.location.href = url
          } else {
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()
            a.parentElement.removeChild(a)
          }
          return true
        })
      return data
    } catch (error) {
      console.log(error)
      // do something with error
      return []
    }
  }

  return {
    clientCaseGet,
    clientCaseSearch,
    clientCaseActionLogSearch,
    clientCaseActionHistory,
    clientCaseUpdate,
    clientCaseTriage,
    clientCaseConstantsGet,

    clientCaseNoteSearch,
    clientCaseNoteCreate,
    clientCaseNoteGet,

    clientCaseDocumentList,
    clientCaseDocumentsUpload,
    clientCaseDocumentDownload,
    clientCaseCustomerOneTimeUpload,
  }
}

export default useClientCase
