import React, { useState, useEffect } from 'react'

import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'

function DataTable({
  title,
  tableRows,
  seeMoreState,
  seeMoreFunction,
  tableHeadStyling,
  tableCellStyling,
}) {
  const [cols, setCols] = useState([])

  useEffect(() => {
    if (tableRows?.length > 0) setCols(Object.keys(tableRows[0]))
  }, [tableRows])

  return (
    <>
      <Typography variant="h3">{title}</Typography>
      <Table size="small">
        <TableHead>
          <TableRow>
            {cols.map((col) => (
              <TableCell
                key={col}
                sx={
                  tableHeadStyling !== null &&
                  typeof tableHeadStyling === 'object'
                    ? !(tableHeadStyling[col] || tableHeadStyling.core || false)
                      ? tableHeadStyling || {}
                      : {
                          ...(tableHeadStyling.core || {}),
                          ...(tableHeadStyling[col] || {}),
                        }
                    : {}
                }
              >
                {col}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row, index) => (
            <TableRow key={`row-${index}`}>
              {cols.map((col) => (
                <TableCell
                  key={`${col}-${index}`}
                  sx={
                    tableCellStyling !== null &&
                    typeof tableCellStyling === 'object'
                      ? !(
                          tableCellStyling[col] ||
                          tableCellStyling.core ||
                          false
                        )
                        ? tableCellStyling || {}
                        : {
                            ...(tableCellStyling.core || {}),
                            ...(tableCellStyling[col] || {}),
                          }
                      : {}
                  }
                >
                  {row[col]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {seeMoreFunction && seeMoreState ? (
        <Link color="primary" href="#" onClick={seeMoreFunction} sx={{ mt: 3 }}>
          See more
        </Link>
      ) : null}
    </>
  )
}

export default DataTable
