import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'

import isValidToken from 'helpers/auth/isValidToken'

import useCustomerAuth from 'hooks/context/useCustomerAuth'

function PortalAuthGuard({ children }) {
  const location = useLocation()
  let { token, isAuthenticated, isInitialized } = useCustomerAuth()

  if (!isInitialized) {
    return <div />
  }
  if (!isValidToken(token || {})) {
    if (!location.pathname.startsWith('/portal/login')) {
      return <Navigate to="/portal/login" replace />
    }
  } else if (isAuthenticated && location.pathname.startsWith('/portal/login')) {
    return <Navigate to="/portal/dashboard" replace />
  }

  return <>{children}</>
}

export default PortalAuthGuard
