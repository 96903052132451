import { useContext } from 'react'
import { CustomerAuthContext } from 'contexts/CustomerAuthContext'

// wrapper for SampleContext
const useCustomerAuth = () => {
  const context = useContext(CustomerAuthContext)

  if (!context)
    throw new Error('CustomerAuthContext must be placed within AuthProvider')

  return context
}

export default useCustomerAuth
