import axios from 'utils/axios'

const useTimeExpenseLog = () => {
  const timeLogSearch = async (searchTerms) => {
    try {
      const { data } = await axios.post(`/timelog/search`, searchTerms)
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const expenseLogSearch = async (searchTerms) => {
    try {
      const { data } = await axios.post(`/expenselog/search`, searchTerms)
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const timeLogCreate = async (
    userID,
    clientCaseID,
    timeLogDate,
    timeLogDescription,
    timeLogHours,
    timeLogMinutes
  ) => {
    let payload = {
      client_case_id: clientCaseID,
      time_log_date: timeLogDate,
      time_log_Description: timeLogDescription,
      time_log_hours: timeLogHours,
      time_log_minutes: timeLogMinutes,
    }
    if (userID) {
      payload['user_id'] = userID
    }
    const { data } = await axios.post(`/timelog/create`, payload)
    return data
  }

  const expenseLogCreate = async (
    userID,
    clientCaseID,
    expenseLogDate,
    expenseLogDescription,
    expenseLogCents
  ) => {
    let payload = {
      client_case_id: clientCaseID,
      expense_log_date: expenseLogDate,
      expense_log_Description: expenseLogDescription,
      expense_log_cents: expenseLogCents,
    }
    if (userID) {
      payload['user_id'] = userID
    }
    const { data } = await axios.post(`/expenselog/create`, payload)
    return data
  }

  return {
    timeLogSearch,
    timeLogCreate,
    expenseLogSearch,
    expenseLogCreate,
  }
}

export default useTimeExpenseLog
