import React, { useState } from 'react'
import { Outlet } from 'react-router-dom'

import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import Container from '@mui/material/Container'

import ConsoleNavbar from './Navbar'
import ConsoleSidebar from './Sidebar'

import Footer from 'components/layout/Footer'

import AuthGuard from 'components/guards/AuthGuard'

function Console({ children }) {
  const [open, setOpen] = useState(true)
  const toggleDrawer = () => {
    setOpen(!open)
  }

  return (
    <AuthGuard>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <ConsoleNavbar open={open} toggleDrawer={toggleDrawer} />
        <ConsoleSidebar open={open} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          xs={12}
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Container sx={{ minHeight: 'calc(100vh - 24px)' }}>
            <Toolbar />
            <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
              <Outlet />
              {children}
            </Container>
          </Container>
          <Footer />
        </Box>
      </Box>
    </AuthGuard>
  )
}

export default Console
