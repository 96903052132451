import React, { useRef } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import FormRenderer from 'components/common/FormRenderer'

import useAuth from 'hooks/context/useAuth'

function MFACode({ setFrame }) {
  const formikRef = useRef(null)
  const { enterMFACode, setupMFA } = useAuth()

  const handleBack = () => {
    setFrame(0)
  }

  const handleSubmit = async (values, { setStatus }) => {
    try {
      if (setFrame) await setupMFA({ ...values })
      else
        await enterMFACode({
          ...values,
        })
    } catch (err) {
      setStatus({
        errors: { submit: err.response?.data ?? 'An error has occurred' },
      })
    }
  }

  const renderingData = [
    {
      field: 'code',
      display: 'Authenticator code',
      type: 'number',
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
      onClick: () => formikRef?.current?.submitForm(),
    },
    {
      children: 'Back',
      color: 'primary',
      variant: 'outlined',
      onClick: handleBack,
    },
  ]

  if (!setFrame) delete footerActionProps[1]

  return (
    <Box>
      <Typography component="h1" variant="h1">
        2-Step Verification
      </Typography>
      <Typography mb={4}>Enter the code from your authenticator app</Typography>
      <FormRenderer
        renderingData={renderingData}
        footerActionProps={footerActionProps}
        innerRef={formikRef}
        handleSubmit={handleSubmit}
      />
    </Box>
  )
}

export default MFACode
