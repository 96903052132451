import React from 'react'
import { Link } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'

function Header() {
  return (
    <>
      <AppBar color="background">
        <Toolbar>
          <Typography variant="h1">Appeal4YourHealth</Typography>
          <Box
            sx={{
              textAlign: 'right',
              width: '100%',
              '& > .MuiButton-root': { mr: 4 },
            }}
          >
            <Button
              color="primary"
              variant="contained"
              LinkComponent={Link}
              to="/diy"
            >
              Do It Yourself
            </Button>
            <Button
              color="primary"
              variant="contained"
              LinkComponent={Link}
              to="/customer-intake"
            >
              Do It For Me
            </Button>
            <Button
              color="primary"
              variant="contained"
              LinkComponent={Link}
              to="/console/user-login"
            >
              Console Login
            </Button>
            <Button
              color="primary"
              variant="contained"
              LinkComponent={Link}
              to="/portal/login"
            >
              Customer Login
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Box sx={{ height: '64px' }} />
    </>
  )
}

export default Header
