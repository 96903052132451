import React from 'react'

import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function Footer() {
  return (
    <Box component="footer">
      <Typography textAlign="center">
        Copyright © Appeal4YourHealth 2021
      </Typography>
    </Box>
  )
}

export default Footer
