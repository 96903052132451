import React from 'react'

import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'

function SingleTitle({ title, children }) {
  return (
    <>
      <Typography variant="h1" textAlign="center">
        {title}
      </Typography>
      <Container maxWidth="sm" sx={{ my: 4 }}>
        {children}
      </Container>
    </>
  )
}

export default SingleTitle
