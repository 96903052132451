import React, { createContext, useEffect, useReducer } from 'react'

import axios from 'utils/axios'

const INITIALIZE = 'INITIALIZE'

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  about: {},
}

const SampleReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        about: action.payload.about,
      }
    default:
      return state
  }
}

const SampleContext = createContext(null)

function SampleProvider({ children }) {
  const [state, dispatch] = useReducer(SampleReducer, initialState)

  const login = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_API_URL}/about`
      )
      dispatch({
        type: INITIALIZE,
        payload: { about: data, isAuthenticated: true },
      })
    } catch (error) {
      dispatch({
        type: INITIALIZE,
        payload: { user: {}, isAuthenticated: false },
      })
    }
  }

  const initialize = async () => {
    await login()
  }

  // assess auth on load
  useEffect(initialize, [])

  return (
    <SampleContext.Provider
      value={{
        ...state,
        login,
        initialize,
      }}
    >
      {children}
    </SampleContext.Provider>
  )
}

export { SampleContext, SampleProvider }
