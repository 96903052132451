import React from 'react'

import FormRenderer from 'components/common/FormRenderer'

function CaseTriage() {
  const renderingData = [
    {
      field: 'health-insurance-company',
      display: 'Health Insurance Company',
    },
    {
      field: 'policyholder-relationship',
      display: 'Relationship with Policyholder',
    },
    {
      field: 'response-due-date',
      display: 'Date that response is due to insurance company',
    },
  ]

  const footerActionProps = [
    {
      children: 'Submit',
      color: 'primary',
      variant: 'contained',
    },
  ]

  return (
    <FormRenderer
      renderingData={renderingData}
      footerActionProps={footerActionProps}
    />
  )
}

export default CaseTriage
