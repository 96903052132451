import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { styled } from '@mui/material/styles'
import MuiAppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import Badge from '@mui/material/Badge'
import Popover from '@mui/material/Popover'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'

import MenuIcon from '@mui/icons-material/Menu'
import Avatar from '@mui/material/Avatar'
import UserIcon from '@mui/icons-material/Person'

import useAuth from 'hooks/context/useAuth'

const drawerWidth = 240

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

function Navbar({ open, toggleDrawer }) {
  const location = useLocation()
  const { logout, user } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null)
  const [pageName, setPageName] = useState('')
  const [userInitials, setUserInitials] = useState('')

  useEffect(() => {
    const path = location.pathname.split('/')
    setPageName(path[path.length - 1].replace(/-/gm, ' '))
  }, [location])

  useEffect(() => {
    let initials = ''
    if (user && user.name) {
      let nameParts = user.name.split(' ')
      for (let i in nameParts) {
        let namePart = nameParts[i] ? nameParts[i].trim() : ''
        if (namePart) {
          initials += namePart[0].toUpperCase()
        }
      }
    }
    setUserInitials(initials)
  }, [user])

  return (
    <>
      <AppBar position="absolute" open={open}>
        <Toolbar
          sx={{
            pr: '24px',
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            sx={{ flexGrow: 1, textTransform: 'capitalize' }}
          >
            {pageName}
          </Typography>
          <IconButton
            color="inherit"
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            <Badge color="success">
              {userInitials ? <Avatar>{userInitials}</Avatar> : <UserIcon />}
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Popover
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <List>
          <ListItem button onClick={logout}>
            Logout
          </ListItem>
        </List>
      </Popover>
    </>
  )
}

export default Navbar
