import React from 'react'

import Content from 'components/dashboard/Content'

function Dashboard() {
  return (
    <>
      <Content />
    </>
  )
}

export default Dashboard
