import React from 'react'
import styled from 'styled-components/macro'
import { Link } from 'react-router-dom'

import Container from '@mui/material/Container'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'

import useSample from 'hooks/context/useSample'

const ContentWrapper = styled(Box)`
  & > .MuiBox-root:nth-child(odd) {
    background-color: white;
  }
`

const TextSection = styled(Box)`
  background-color: rgb(245, 245, 245);
  display: flex;
  height: 45vh;
  & > .MuiContainer-root {
    margin-top: auto;
    margin-bottom: auto;
    text-align: center;
  }
`

function ContentSection({ children }) {
  return (
    <TextSection>
      <Container maxWidth="md">
        <Typography fontSize="large">{children}</Typography>
      </Container>
    </TextSection>
  )
}

function Content() {
  const { about } = useSample()
  const textContent =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.'

  return (
    <ContentWrapper>
      <ContentSection>{about?.host}</ContentSection>
      <ContentSection>
        <Button LinkComponent={Link} to="/console/user-login">User Login Page</Button>
        <Button LinkComponent={Link} to="/console">Console Page</Button>
      </ContentSection>
      {[0, 1, 2, 3].map((key) => (
        <ContentSection key={key}>{textContent}</ContentSection>
      ))}
    </ContentWrapper>
  )
}

export default Content
