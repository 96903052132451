import axios from 'utils/axios'

const useUser = () => {
  const userGet = async (userID) => {
    try {
      const { data } = await axios.get(`/user/get`, {
        params: {
          userID: userID,
        },
      })
      return data
    } catch (error) {
      // do something with error
      return null
    }
  }

  const userSearch = async (searchTerms) => {
    try {
      const { data } = await axios.get(`/user/search`, { params: searchTerms })
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  const userUpdate = async (userPayload) => {
    const { data } = await axios.post(`/user/update`, userPayload)
    return data
  }

  const userCreate = async (userPayload) => {
    const { data } = await axios.post(`/user/create`, userPayload)
    return data
  }

  const userSetPasswordToTemp = async (userID) => {
    await axios.post('/user/setPasswordToTemp', { user_id: userID })
  }

  const userClearMFA = async (userID) => {
    await axios.post('/user/clearmfa', { user_id: userID })
  }

  return {
    userGet,
    userSearch,
    userCreate,
    userUpdate,
    userClearMFA,
    userSetPasswordToTemp,
  }
}

export default useUser
