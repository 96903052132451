import { useContext } from 'react'
import { SampleContext } from 'contexts/SampleContext'

// wrapper for SampleContext
const useSample = () => {
  const context = useContext(SampleContext)

  if (!context)
    throw new Error('SampleContext must be placed within SampleProvider')

  return context
}

export default useSample
