import axios from 'utils/axios'

const useDIY = () => {
  const diyData = async (token) => {
    const { data } = await axios.get(`/diy/list/${token}`)
    return data
  }

  const documentDownload = async (documentID, authHeader) => {
    try {
      // build fromdata
      const { data } = await axios
        .get(`/diy/download`, {
          params: { documentID: documentID },
          headers: {
            Authorization: `Bearer ${authHeader}`,
          },
          responseType: 'blob',
        })
        .then((response) => {
          let filename = ''
          const url = window.URL.createObjectURL(
            new Blob([response.data], {
              type: response.headers['content-type'],
            })
          )
          let disposition = response.headers['content-disposition']

          if (disposition && disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
              matches = filenameRegex.exec(disposition)

            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, '')
            }
          }

          let a = document.createElement('a')
          if (typeof a.download === 'undefined') {
            window.location.href = url
          } else {
            a.href = url
            a.download = filename
            document.body.appendChild(a)
            a.click()
            a.parentElement.removeChild(a)
          }
          return true
        })
      return data
    } catch (error) {
      console.log(error)
      // do something with error
      return []
    }
  }

  const authDIYSearch = async (searchTerms) => {
    try {
      const { data } = await axios.post(`/diy/intake/search`, searchTerms)
      return data
    } catch (error) {
      // do something with error
      return []
    }
  }

  return {
    diyData,
    documentDownload,
    authDIYSearch,
  }
}

export default useDIY
