import React from 'react'
import { Navigate } from 'react-router-dom'

// layouts
import PublicFacingLayout from 'layouts/PublicFacing'
import ConsoleLayout from 'layouts/Console'
import PortalLayout from 'layouts/Portal'
import AuthLayout from 'layouts/Auth'

// guards
import AuthGuard from 'components/guards/AuthGuard'
import LoginGuard from 'components/guards/LoginGuard'
import ConsoleGuestGuard from 'components/guards/ConsoleGuestGuard'
import PortalAuthGuard from 'components/guards/PortalAuthGuard'

// pages
import Home from 'pages/Home'
import DIYForm from 'pages/DIYForm'
import DIYThanks from 'pages/DIYThanks'
import DIYLetterDownload from 'pages/DIYLetterDownload'
import CustomerIntakeForm from 'pages/CustomerIntakeForm'
// user pages
import Dashboard from 'pages/console/Dashboard'
import DIYIntakeLog from 'pages/console/DIYIntakeLog'
import CaseBrowser from 'pages/console/CaseBrowser'
import CaseTriage from 'pages/console/CaseTriage'
import CaseDetail from 'pages/console/CaseDetail'
import Users from 'pages/console/Users'
import UserDetail from 'pages/console/UserDetail'
import TimeExpenseLog from 'pages/console/TimeExpenseLog'

// Customer Pages
import CustomerLogin from 'pages/portal/CustomerLogin'
import CustomerDashboard from 'pages/portal/Dashboard'
import CustomerCaseDetail from 'pages/portal/CustomerCaseDetail'

// User login
import UserLogin from 'pages/console/UserLogin'
import UserLoginPage from 'pages/console/UserLogin/Login'
import UserLoginResetPassword from 'pages/console/UserLogin/ResetPassword'
import UserLoginMFA from 'pages/console/UserLogin/MFA'
import UserLoginMFACode from 'pages/console/UserLogin/MFACode'

// auth providers
import { AuthProvider } from 'contexts/AuthContext'
import { CustomerAuthProvider } from 'contexts/CustomerAuthContext'

const routes = [
  {
    path: '/',
    element: <PublicFacingLayout />,
    children: [
      {
        path: '',
        element: <Home />,
      },
      {
        path: 'diy/download/:token',
        element: <DIYLetterDownload />,
      },
      {
        path: 'diy',
        element: <DIYForm />,
      },
      {
        path: 'diythanks',
        element: <DIYThanks />,
      },
      {
        path: 'customer-intake',
        element: (
          <CustomerAuthProvider>
            <CustomerIntakeForm />
          </CustomerAuthProvider>
        ),
      },
    ],
  },
  {
    path: '/portal/login',
    element: (
      <CustomerAuthProvider>
        <PortalAuthGuard>
          <AuthLayout>
            <CustomerLogin />
          </AuthLayout>
        </PortalAuthGuard>
      </CustomerAuthProvider>
    ),
  },
  {
    path: '/portal',
    element: (
      <CustomerAuthProvider>
        <PortalAuthGuard>
          <PortalLayout />
        </PortalAuthGuard>
      </CustomerAuthProvider>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/portal/dashboard" />,
      },
      {
        path: 'dashboard',
        element: <CustomerDashboard />,
      },
      {
        path: 'case-detail/:id',
        element: <CustomerCaseDetail />,
      },
    ],
  },
  {
    path: '/console/user-login',
    element: (
      <AuthProvider>
        <AuthLayout>
          <ConsoleGuestGuard>
            <UserLogin />
          </ConsoleGuestGuard>
        </AuthLayout>
      </AuthProvider>
    ),
    children: [
      {
        path: '',
        element: (
          <LoginGuard>
            <UserLoginPage />
          </LoginGuard>
        ),
      },
      {
        path: 'reset-password',
        element: (
          <AuthGuard>
            <UserLoginResetPassword />
          </AuthGuard>
        ),
      },
      {
        path: 'challenge',
        children: [
          {
            path: 'auth-code',
            element: (
              <AuthGuard>
                <UserLoginMFACode />
              </AuthGuard>
            ),
          },
          {
            path: 'reset-password',
            element: (
              <AuthGuard>
                <UserLoginResetPassword />
              </AuthGuard>
            ),
          },
          {
            path: 'mfa-initialize',
            element: (
              <AuthGuard>
                <UserLoginMFA />
              </AuthGuard>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '/console',
    element: (
      <AuthProvider>
        <ConsoleLayout />
      </AuthProvider>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="/console/dashboard" />,
      },
      {
        path: 'dashboard',
        element: <Dashboard />,
      },
      {
        path: 'diy-intake-log',
        element: <DIYIntakeLog />,
      },
      {
        path: 'case-browser',
        element: <CaseBrowser />,
      },
      {
        path: 'case-triage',
        element: <CaseTriage />,
      },
      {
        path: 'case-detail/:id',
        element: <CaseDetail />,
      },
      {
        path: 'users/:userId',
        element: <UserDetail />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'time-expense-log',
        element: <TimeExpenseLog />,
      },
      {
        path: 'reset-password',
        element: (
          <AuthGuard>
            <UserLoginResetPassword />
          </AuthGuard>
        ),
      },
    ],
  },
]

export default routes
