import React from 'react'
import dotenv from 'dotenv-flow'
import { useRoutes } from 'react-router-dom'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { create } from 'jss'
import { ThemeProvider } from 'styled-components/macro'

import { StyledEngineProvider } from '@mui/styled-engine-sc'
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import StylesProvider from '@mui/styles/StylesProvider'
import jssPreset from '@mui/styles/jssPreset'

import createTheme from './theme'
import routes from './routes'

import { SampleProvider } from 'contexts/SampleContext'

dotenv.config()

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point'),
})

function App() {
  const theme = 'DEFAULT'
  const content = useRoutes(routes)

  return (
    <HelmetProvider>
      <Helmet titleTemplate="%s | A4YH" defaultTitle="A4YH" />
      <StylesProvider jss={jss}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StyledEngineProvider injectFirst>
            <MuiThemeProvider theme={createTheme(theme)}>
              <ThemeProvider theme={createTheme(theme)}>
                <SampleProvider>{content}</SampleProvider>
              </ThemeProvider>
            </MuiThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </StylesProvider>
    </HelmetProvider>
  )
}

export default App
